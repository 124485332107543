/* eslint react/jsx-filename-extension: 0 */
import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import AccountSetupContainer from './containers/AccountSetupContainer';
import ConversationActivityContainer from './containers/ConversationActivityContainer';
import MessagingAnalyticsContainer from './containers/MessagingAnalyticsContainer';
import AppointmentManagerContainer from './components/AppointmentManager';
import AuditLogContainer from './containers/AuditLogContainer';
import AuthCallback from './components/AuthCallback';
import AuthLogin from './components/AuthLogin';
import ChangePasswordContainer from './containers/ChangePasswordContainer';
import ChatInboxView from './views/ChatInboxView';
import ContactFormContainer from './containers/ContactFormContainer';
import Contacts from './components/Contacts';
import ContactListForm from './components/ContactListForm';
import CSVImportContainer from './containers/CSVImportContainer';
import DiagnosticsContainer from './containers/DiagnosticsContainer';
import ForgotPasswordContainer from './containers/ForgotPasswordContainer';
import InboxContainer from './containers/InboxContainer';
import InboxMentionContainer from './containers/InboxMentionContainer';
import ThreadView from './views/ThreadView';
import IntegrationAnalyticsContainer from './containers/IntegrationAnalyticsContainer';
import LegacyLoginContainer from './containers/LegacyLoginContainer';
import NotFound from './components/NotFound';
import AppointmentCampaigns from './components/AppointmentCampaigns';
import HipaaCampaignFormView from './views/HipaaCampaignFormView';
import AppointmentCampaignForm from './views/AppointmentCampaignForm';
import AppointmentCampaignChannelForm from './components/AppointmentCampaignChannelForm';
import OrganizationBillingContainer from './containers/OrganizationBillingContainer';
import OrganizationChannelFormContainer from './containers/OrganizationChannelFormContainer';
import OrganizationChannelsContainer from './containers/OrganizationChannelsContainer';
import OrganizationGroupFormContainer from './containers/OrganizationGroupFormContainer';
import OrganizationGroups from './components/OrganizationGroups';
import OrganizationMemberFormContainer from './containers/OrganizationMemberFormContainer';
import OrganizationMessageTemplateForm from './components/OrganizationMessageTemplateForm';
import OrganizationOfficeFormContainer from './containers/OrganizationOfficeFormContainer';
import OrganizationOfficesContainer from './containers/OrganizationOfficesContainer';
import OrganizationOutOfOfficeContainer from './containers/OrganizationOutOfOfficeContainer';
import OrganizationOutOfOfficeFormContainer from './containers/OrganizationOutOfOfficeFormContainer';
import OrganizationPreferencesContainer from './containers/OrganizationPreferencesContainer';
import OrganizationProfileContainer from './containers/OrganizationProfileContainer';
import OrganizationRolesContainer from './containers/OrganizationRolesContainer';
import OrganizationRolesFormContainer from './containers/OrganizationRolesFormContainer';
import OrganizationTagsContainer from './containers/OrganizationTagsContainer';
import requireAuthentication from './components/AuthenticatedComponent';
import RhinoPayManagerContainer from './containers/RhinoPayManagerContainer';
import RhinopayPaymentContainer from './containers/RhinopayPaymentContainer';
import SavedContentPreviewView from './views/SavedContentPreviewView';
import SecureThreadContainer from './containers/SecureThreadContainer';
import SecureUserProfileContainer from './containers/SecureUserProfileContainer';
import SelectOrganizationContainer from './containers/SelectOrganizationContainer';
import SignupContainer from './containers/SignupContainer';
import SystemAlertSettingsContainer from './containers/SystemAlertSettingsContainer';
import UserPreferencesContainer from './containers/UserPreferencesContainer';
import RhinovideoConferenceView from './components/RhinovideoConferenceView';
import RhinovideoPreconfig from './components/RhinovideoPreconfig';
import OrganizationLibraryContainer from './containers/OrganizationLibraryContainer';
import RhinoformViewContainer from './containers/RhinoformViewContainer';
import AppointmentDataSetup from './components/AppointmentDataSetup';
import FormsManager from './components/FormsManager';
import RoutingManager from './components/RoutingManager';
import CategoryRouteForm from './components/CategoryRouteForm';
import PrescriptionCampaignsView from './views/PrescriptionCampaignsView';
import PrescriptionManagerView from './views/PrescriptionManagerView';
import PrescriptionCampaignForm from './components/PrescriptionCampaignForm';
import BlastManager from './views/BlastManagerView';
import AxiumLogin from './views/AxiumLogin';
import RhinoPhone from './components/RhinoPhone';
import OrganizationMembersView from './views/OrganizationMembersView';
import SubscriptionComponent from './components/Subscription';

// Permissions for route guards
import {
  ANALYTICS_VIEW,
  APPOINTMENT_MANAGER_VIEW,
  APPOINTMENT_TYPE_VIEW,
  AUDIT_LOG_VIEW,
  AUTOMATED_MESSAGE_EDIT,
  AUTOMATED_MESSAGE_VIEW,
  BILLING_VIEW,
  BULK_MESSAGING_EDIT,
  CHANNEL_EDIT,
  CHANNEL_FACEBOOK_CREATE,
  CHANNEL_RHINOGRAM_CREATE,
  CHANNEL_SECURE_CREATE,
  CHANNEL_SMS_CREATE,
  CHANNEL_VIEW,
  CONTACT_CREATE,
  CONTACT_EDIT,
  CONTACT_ORGANIZATION_CREATE,
  CONTACT_VIEW,
  CONVERSATION_CONTACT_MOBILE,
  CONVERSATION_TEAM_MOBILE,
  FORM_EDIT,
  FORM_VIEW,
  GROUP_CHAT_EDIT,
  GROUP_INBOX_EDIT,
  GROUP_VIEW,
  MEMBER_CREATE,
  MEMBER_EDIT,
  MEMBER_VIEW,
  OFFICE_CREATE,
  OFFICE_EDIT,
  OFFICE_VIEW,
  ORGANIZATION_PREFERENCES_VIEW,
  ORGANIZATION_PROFILE_VIEW,
  OUT_OF_OFFICE_CREATE,
  OUT_OF_OFFICE_EDIT,
  OUT_OF_OFFICE_VIEW,
  RAMP_IMPORT_PATIENT_CREATE,
  RAMP_ORGANIZATION_VIEW,
  RHINOPAY_MANAGER_VIEW,
  ROLE_CREATE,
  ROLE_EDIT,
  ROLE_VIEW,
  TAG_VIEW,
  TEAM_THREAD_VIEW,
  TEMPLATE_MESSAGE_CREATE,
  TEMPLATE_MESSAGE_EDIT,
  TEMPLATE_MESSAGE_VIEW,
  THREAD_VIEW,
  ROUTING_MANAGER_VIEW,
  PRESCRIPTION_MANAGER_VIEW,
  PRESCRIPTION_NOTIFICATION_EDIT,
  PRESCRIPTION_NOTIFICATION_VIEW,
} from './constants/UserPermissionsConstants';
import HipaaCampaignsView from './views/HipaaCampaignsView';
import CampaignAnalyticsView from './views/CampaignAnalyticsView';
import RestrictedView from './views/RestrictedView';
import AxiumThreadView from './views/AxiumThreadView.jsx';
// Temporary support for legacy logins, will be removed after a couple release cycles
const routes = (
  <Switch>
    {/* http://localhost:3000/inbox/group/1 */}
    <Route path="/notfound" component={NotFound} />
    <Route path="/restricted" component={RestrictedView} />
    <Route path="/appointment-reminders" component={requireAuthentication(AppointmentCampaigns, [AUTOMATED_MESSAGE_VIEW])} />
    <Route path="/axium/login/:axiumOrgId" component={AxiumLogin} />
    <Route path="/axium/inbox/all/user/:userId" component={requireAuthentication(AxiumThreadView, [THREAD_VIEW], [CONVERSATION_CONTACT_MOBILE])} />
    <Route path="/video/:videoId" component={RhinovideoConferenceView} />
    <Route path="/memberVideo/" component={RhinovideoPreconfig} />
    <Route path="/callback" component={AuthCallback} />
    <Route exact path={['/inbox', '/axium/inbox']} component={requireAuthentication(InboxContainer, [THREAD_VIEW], [CONVERSATION_CONTACT_MOBILE])} />
    <Route path="/inbox/user/:userId" component={requireAuthentication(ThreadView, [THREAD_VIEW], [CONVERSATION_CONTACT_MOBILE])} />
    <Route path="/axium/inbox/user/:userId" component={requireAuthentication(ThreadView, [THREAD_VIEW], [CONVERSATION_CONTACT_MOBILE])} />
    <Route exact path="/inbox/group/:groupId" component={requireAuthentication(InboxContainer, [THREAD_VIEW], [CONVERSATION_CONTACT_MOBILE])} />
    <Route exact path="/inbox/group/:groupId/user/:userId" component={requireAuthentication(ThreadView, [THREAD_VIEW], [CONVERSATION_CONTACT_MOBILE])} />
    <Route exact path="/inbox/direct" component={requireAuthentication(InboxContainer, [THREAD_VIEW], [CONVERSATION_CONTACT_MOBILE])} />
    <Route exact path="/inbox/assigned" component={requireAuthentication(InboxContainer, [THREAD_VIEW], [CONVERSATION_CONTACT_MOBILE])} />
    <Route exact path="/inbox/following" component={requireAuthentication(InboxContainer, [THREAD_VIEW], [CONVERSATION_CONTACT_MOBILE])} />
    <Route path="/inbox/following/user/:userId" component={requireAuthentication(ThreadView, [THREAD_VIEW], [CONVERSATION_CONTACT_MOBILE])} />
    <Route path="/inbox/direct/user/:userId" component={requireAuthentication(ThreadView, [THREAD_VIEW], [CONVERSATION_CONTACT_MOBILE])} />
    <Route path="/inbox/assigned/user/:userId" component={requireAuthentication(ThreadView, [THREAD_VIEW], [CONVERSATION_CONTACT_MOBILE])} />
    <Route exact path="/inbox/all/user/:userId" component={requireAuthentication(ThreadView, [THREAD_VIEW], [CONVERSATION_CONTACT_MOBILE])} />
    <Route path="/inbox/mentions" component={requireAuthentication(InboxMentionContainer, [TEAM_THREAD_VIEW], [CONVERSATION_TEAM_MOBILE])} />
    <Route path="/axium/inbox/mentions" component={requireAuthentication(InboxMentionContainer)} />
    <Route exact path="/chat" component={requireAuthentication(ChatInboxView, [TEAM_THREAD_VIEW], [CONVERSATION_TEAM_MOBILE])} />
    <Route exact path="/chat/direct" component={requireAuthentication(ChatInboxView, [TEAM_THREAD_VIEW], [CONVERSATION_TEAM_MOBILE])} />
    <Route path="/chat/user/:userId" component={requireAuthentication(ThreadView, [TEAM_THREAD_VIEW], [CONVERSATION_TEAM_MOBILE])} />
    <Route path="/chat/group/:groupId" component={requireAuthentication(ThreadView, [TEAM_THREAD_VIEW], [CONVERSATION_TEAM_MOBILE])} />
    <Route
      exact
      path="/contacts/create"
      component={requireAuthentication(ContactFormContainer, [CONTACT_CREATE, CONTACT_ORGANIZATION_CREATE], [CONVERSATION_CONTACT_MOBILE])}
    />
    <Route exact path="/contacts/import/users" component={requireAuthentication(CSVImportContainer, [RAMP_IMPORT_PATIENT_CREATE])} />
    <Route exact path="/contacts/import/connectedParties" component={requireAuthentication(CSVImportContainer, [RAMP_IMPORT_PATIENT_CREATE])} />
    <Route exact path="/contacts/import/appointments" component={requireAuthentication(CSVImportContainer)} />
    <Route exact path="/contacts/edit/:userId" component={requireAuthentication(ContactFormContainer, [CONTACT_EDIT], [CONVERSATION_CONTACT_MOBILE])} />
    <Route path="/contacts/:userId?" component={requireAuthentication(Contacts, [CONTACT_VIEW], [CONVERSATION_CONTACT_MOBILE])} />
    <Route exact path="/secure/org/:organizationId/channel/all" component={requireAuthentication(SecureThreadContainer, undefined, undefined, true)} />
    <Route exact path="/secure/org/:organizationId/channel/:channelId" component={requireAuthentication(SecureThreadContainer, undefined, undefined, true)} />
    <Route path="/secure/profile" component={requireAuthentication(SecureUserProfileContainer, undefined, undefined, true)} />
    <Route path="/diagnostics" component={DiagnosticsContainer} />
    <Route path="/analytics/messaging" component={requireAuthentication(MessagingAnalyticsContainer, [ANALYTICS_VIEW])} />
    <Route path="/analytics/conversation-activity" component={requireAuthentication(ConversationActivityContainer, [ANALYTICS_VIEW])} />
    <Route path="/analytics/auditLog" component={requireAuthentication(AuditLogContainer, [AUDIT_LOG_VIEW])} />
    <Route path="/analytics/hipaa-campaigns" component={requireAuthentication(CampaignAnalyticsView, [ANALYTICS_VIEW])} />
    <Route path="/login" component={LegacyLoginContainer} />
    <Route path="/auth" component={AuthLogin} />
    <Route path="/forgot-password" component={ForgotPasswordContainer} />
    <Route path="/change-password" component={requireAuthentication(ChangePasswordContainer)} />
    <Route path="/signup" component={SignupContainer} />
    <Route path="/selectorg" component={SelectOrganizationContainer} />
    <Route path="/systemalert" component={SystemAlertSettingsContainer} />
    <Route path="/accountsetup" component={AccountSetupContainer} />
    <Route path="/rhinopay/:paymentRequestCode" component={RhinopayPaymentContainer} />
    <Route exact path="/contact-list/create" component={requireAuthentication(ContactListForm, [BULK_MESSAGING_EDIT])} />
    <Route exact path="/contact-list/:contactListId?" component={requireAuthentication(Contacts, [BULK_MESSAGING_EDIT, RAMP_ORGANIZATION_VIEW])} />
    <Route path="/contact-list/edit/:contactListId" component={requireAuthentication(ContactListForm, [BULK_MESSAGING_EDIT])} />
    <Route
      path="/saved-content/:savedContentItemId"
      component={requireAuthentication(SavedContentPreviewView, [CONTACT_VIEW, THREAD_VIEW], [CONVERSATION_CONTACT_MOBILE], false, true)}
    />
    <Route exact path="/phone/:pageId" component={requireAuthentication(RhinoPhone, [ORGANIZATION_PROFILE_VIEW])} />
    <Route exact path="/settings/organization/profile" component={requireAuthentication(OrganizationProfileContainer, [ORGANIZATION_PROFILE_VIEW])} />
    <Route exact path="/settings/organization/roles/create" component={requireAuthentication(OrganizationRolesFormContainer, [ROLE_CREATE])} />
    <Route exact path="/settings/organization/roles/(system)?/:roleId?" component={requireAuthentication(OrganizationRolesContainer, [ROLE_VIEW])} />
    {/* TODO: Include permission for appointment manager data whenever that's added.  */}
    <Route path="/settings/organization/appointment-data-setup" component={requireAuthentication(AppointmentDataSetup, [APPOINTMENT_TYPE_VIEW])} />
    <Route path="/managers/appointment-manager" component={requireAuthentication(AppointmentManagerContainer, [APPOINTMENT_MANAGER_VIEW])} />
    <Route path="/managers/rhinoforms-manager" component={requireAuthentication(FormsManager, [ANALYTICS_VIEW, FORM_VIEW])} />
    <Route path="/managers/routing-manager/create" component={requireAuthentication(CategoryRouteForm)} />
    <Route path="/managers/routing-manager/edit/:categoryRouteId" component={requireAuthentication(CategoryRouteForm)} />
    <Route
      path="/managers/blast-manager/:campaignId"
      component={requireAuthentication(BlastManager)}
    />
    <Route
      path="/managers/blast-manager"
      component={requireAuthentication(BlastManager)}
    />
    <Route
      path="/managers/routing-manager/:categoryRouteId?"
      component={requireAuthentication(RoutingManager, [ROUTING_MANAGER_VIEW])}
    />
    <Route
      path="/managers/prescriptions"
      component={requireAuthentication(PrescriptionManagerView, [PRESCRIPTION_MANAGER_VIEW])}
    />
    <Route
      path="/managers/routing-manager"
      component={requireAuthentication(RoutingManager, [ROUTING_MANAGER_VIEW])}
    />
    <Route
      path="/settings/organization/prescription-notifications/create"
      component={requireAuthentication(PrescriptionCampaignForm, [PRESCRIPTION_NOTIFICATION_EDIT])}
    />
    <Route
      path="/settings/organization/prescription-notifications/edit/:prescriptionCampaignId?"
      component={requireAuthentication(PrescriptionCampaignForm, [PRESCRIPTION_NOTIFICATION_EDIT])}
    />
    <Route
      path="/settings/organization/prescription-notifications/:prescriptionCampaignId?"
      component={requireAuthentication(PrescriptionCampaignsView, [PRESCRIPTION_NOTIFICATION_VIEW])}
    />
    <Route exact path="/settings/organization/roles/(system)?/edit/:roleId" component={requireAuthentication(OrganizationRolesFormContainer, [ROLE_EDIT])} />
    <Route
      exact
      path="/settings/organization/appointment-reminders/create"
      component={requireAuthentication(AppointmentCampaignForm, [AUTOMATED_MESSAGE_EDIT])}
    />
    <Route
      exact
      path="/settings/organization/hipaa-campaigns/create"
      component={requireAuthentication(HipaaCampaignFormView, [AUTOMATED_MESSAGE_EDIT])}
    />
    <Route
      exact
      path="/settings/organization/hipaa-campaigns"
      component={requireAuthentication(HipaaCampaignsView, [AUTOMATED_MESSAGE_EDIT])}
    />
    <Route
      exact
      path="/settings/organization/hipaa-campaigns"
      component={requireAuthentication(HipaaCampaignFormView, [AUTOMATED_MESSAGE_EDIT])}
    />
    <Route
      path="/settings/organization/hipaa-campaigns/edit/:hipaaCampaignId"
      component={requireAuthentication(HipaaCampaignFormView, [AUTOMATED_MESSAGE_EDIT])}
    />
    <Route
      path="/settings/organization/hipaa-campaigns/:hipaaCampaignId"
      component={requireAuthentication(HipaaCampaignsView, [AUTOMATED_MESSAGE_EDIT])}
    />
    <Route
      exact
      path="/settings/organization/appointment-reminders/default-channel"
      component={requireAuthentication(AppointmentCampaignChannelForm, [AUTOMATED_MESSAGE_EDIT])}
    />
    <Route
      path="/settings/organization/appointment-reminders/edit/:appointmentCampaignId"
      component={requireAuthentication(AppointmentCampaignForm, [AUTOMATED_MESSAGE_EDIT])}
    />
    <Route
      path="/settings/organization/appointment-reminders/:appointmentCampaignId?"
      component={requireAuthentication(AppointmentCampaigns, [AUTOMATED_MESSAGE_VIEW])}
    />
    <Route path="/settings/organization/preferences" component={requireAuthentication(OrganizationPreferencesContainer, [ORGANIZATION_PREFERENCES_VIEW])} />
    <Route
      exact
      path="/settings/organization/channels/create"
      component={requireAuthentication(OrganizationChannelFormContainer, [CHANNEL_FACEBOOK_CREATE, CHANNEL_RHINOGRAM_CREATE, CHANNEL_SECURE_CREATE, CHANNEL_SMS_CREATE])}
    />
    <Route exact path="/settings/organization/channels/edit/:channelId" component={requireAuthentication(OrganizationChannelFormContainer, [CHANNEL_EDIT])} />
    <Route exact path="/settings/organization/channels/:channelId?" component={requireAuthentication(OrganizationChannelsContainer, [CHANNEL_VIEW])} />
    <Route exact path="/settings/organization/channels/pending/:channelId" component={requireAuthentication(OrganizationChannelsContainer, [CHANNEL_VIEW])} />
    <Route exact path="/settings/organization/out-of-office/create" component={requireAuthentication(OrganizationOutOfOfficeFormContainer, [OUT_OF_OFFICE_CREATE])} />
    <Route path="/settings/organization/out-of-office/edit/:oooId" component={requireAuthentication(OrganizationOutOfOfficeFormContainer, [OUT_OF_OFFICE_EDIT])} />
    <Route exact path="/settings/organization/out-of-office/:oooId?" component={requireAuthentication(OrganizationOutOfOfficeContainer, [OUT_OF_OFFICE_VIEW])} />
    <Route exact path="/settings/organization/members/create" component={requireAuthentication(OrganizationMemberFormContainer, [MEMBER_CREATE])} />
    <Route exact path="/settings/organization/members/edit/:memberId" component={requireAuthentication(OrganizationMemberFormContainer, [MEMBER_EDIT])} />
    <Route path="/settings/organization/members/:memberId?" component={requireAuthentication(OrganizationMembersView, [MEMBER_VIEW])} />
    <Route path="/settings/organization/tags" component={requireAuthentication(OrganizationTagsContainer, [TAG_VIEW])} />
    <Route path="/settings/organization/groups/create" component={requireAuthentication(OrganizationGroupFormContainer, [GROUP_INBOX_EDIT, GROUP_CHAT_EDIT])} />
    <Route path="/settings/organization/groups/edit/:groupId" component={requireAuthentication(OrganizationGroupFormContainer, [GROUP_INBOX_EDIT, GROUP_CHAT_EDIT])} />
    <Route exact path="/settings/organization/groups/:groupId?" component={requireAuthentication(OrganizationGroups, [GROUP_VIEW])} />
    <Route exact path="/settings/organization/offices/create" component={requireAuthentication(OrganizationOfficeFormContainer, [OFFICE_CREATE])} />
    <Route exact path="/settings/organization/offices/edit/:officeId" component={requireAuthentication(OrganizationOfficeFormContainer, [OFFICE_EDIT])} />
    <Route exact path="/settings/organization/library/forms/create" component={requireAuthentication(RhinoformViewContainer, [FORM_EDIT])} />
    <Route exact path="/settings/organization/library/forms/:id?" component={requireAuthentication(OrganizationLibraryContainer, [FORM_VIEW])} />
    <Route exact path="/settings/organization/library/forms/edit/:id?" component={requireAuthentication(RhinoformViewContainer, [FORM_EDIT])} />
    <Route path="/settings/organization/library/templates/create" component={requireAuthentication(OrganizationMessageTemplateForm, [TEMPLATE_MESSAGE_CREATE])} />
    <Route
      exact
      path="/settings/organization/library/templates/edit/:messageTemplateId"
      component={requireAuthentication(OrganizationMessageTemplateForm, [TEMPLATE_MESSAGE_EDIT])}
    />
    <Route exact path="/settings/organization/library/templates/:id?" component={requireAuthentication(OrganizationLibraryContainer, [TEMPLATE_MESSAGE_VIEW])} />
    <Route path="/settings/organization/offices/:officeId?" component={requireAuthentication(OrganizationOfficesContainer, [OFFICE_VIEW])} />
    <Route path="/settings/organization/offices/:officeId?" component={requireAuthentication(OrganizationOfficesContainer, [OFFICE_VIEW])} />
    <Route path="/settings/organization/billing" component={requireAuthentication(OrganizationBillingContainer, [BILLING_VIEW])} />
    <Route path="/managers/payment-requests" component={requireAuthentication(RhinoPayManagerContainer, [RHINOPAY_MANAGER_VIEW])} />
    <Route path="/settings/organization/integration-analytics" component={IntegrationAnalyticsContainer} />
    <Route path="/settings/profile" component={requireAuthentication(OrganizationMemberFormContainer)} />
    <Route path="/settings/preferences" component={requireAuthentication(UserPreferencesContainer)} />
    <Route path="/settings/organization/subscription" component={SubscriptionComponent} />
    <Route path="/" component={requireAuthentication(InboxContainer)} />
    <Redirect from="*" to="notfound" />
  </Switch>
);

export default routes;
