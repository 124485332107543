import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as OrganizationReducer from '../reducers/organizationReducer';
import * as UploadActions from '../actions/UploadActions';
import { fetchMerchantCcr, createMerchant, updateMerchant } from '../reducers/payReducer';
import * as FormReducer from '../reducers/formReducer';
import { connectOrgWithCustId } from '../reducers/billingReducer';
import * as RhinovideoReducer from '../reducers/rhinovideoReducer';
import * as RhinocallReducer from '../reducers/rhinocallReducer';
import OrganizationProfile from '../components/OrganizationProfile';
import unsavedChanges from '../components/UnsavedChangesHOC';
import { getLoggedInUser, getLoggedInUserOrganization } from '../selectors/userSelectors';
import { getRhinocallChannelIds } from '../selectors/channelSelectors';
import { DataHelpers, ValidationHelpers, RoleHelpers } from '../helpers';
import { ValidationService, ValidationShapers } from '../services/ValidationService';
import PageLoader from '../components/PageLoader';
import { AppConstants, Types } from '../constants';
import * as AuthReducer from '../reducers/authReducer';
import { fetchOrganizationRolesView } from '../reducers/roleReducer';
import * as AuditLogReducer from '../reducers/auditLogReducer';

const { REACT_APP_AVATAR_BUCKET: AVATAR_BUCKET } = process.env;

class OrganizationProfileContainer extends Component {
  state = {
    areHipaaRequestCampaignsEnabled: false,
    arePrescriptionCampaignsEnabled: false,
    isOrganizationLoading: true,
    isMerchantLoading: true,
    isRhinoformOrgLoading: true,
    isRhinocallOrgLoading: true,
    businessEmail: '',
    businessPhone: '',
    contactEmail: '',
    contactPhone: '',
    contactName: '',
    formInProgress: false,
    haveVideoSettingsChanged: false,
    name: '',
    street1: '',
    street2: '',
    city: '',
    state: -1,
    states: [],
    zip: '',
    errors: {},
    uploadAvatarModalOpen: false,
    billManagerCustId: '',
    integration: false,
    isDocumentWriteBackEnabled: false,
    isAppointmentManagerEnabled: false,
    isPrescriptionManagerEnabled: false,
    isOfficeVCardEnabled: false,
    isRhinopayEnabled: false,
    isSavedContentEnabled: false,
    isCloseMentionsForAllMembersEnabled: false,
    isVideoEnabled: false,
    isCallEnabled: false,
    isActive: false,
    videoConferenceLimit: AppConstants.VIDEO_CONFERENCE_MEMBER_LIMIT,
    channelLimit: AppConstants.CALL_CHANNEL_LIMIT,
    merchantId: '',
    merchantToken: '',
    paymentApiUsername: '',
    paymentApiPassword: '',
    paymentGatewayId: '',
    isAchEnabled: false,
    integrationPartnerTypeId: -1,
    paymentType: -1,
    selectedPaymentType: '',
    isBulkMessagingEnabled: false,
    isMFARequired: false,
    isSSOEnabled: false,
    isSSORolesEnabled: false,
    identityProvider: undefined,
    ssoConnections: [],
    isMessageTranslationFeatureEnabled: false,
    isRhinoformEnabled: false,
    rhinoformSeatsLimit: 1,
    isAutomatedMessageToConnectedPartiesEnabled: false,
    isSoundPreferencesEnabled: false,
    isBadgeCountPreferencesEnabled: false,
    isOneReminderPerCalendarDayEnabled: false,
    isConfirmedCodifiedResponseAlertEnabled: false,
    isRoutingManagerEnabled: false,
    parentCompanyId: -1,
    parentCompanyOptions: [],
    isAddParentOrgModalOpen: false,
    companyName: '',
    parentCompanyformErrors: {},
    isRhinoblastMaxEnabled: false,
    isEditIntegratedContactEnabled: false,
    areExtraRemindersEnabled: false,
    enableCampaignAppointmentTypeAndOffice: false,
    disableMessagingHIPAAConsent: false,
    isMessagingForMinorsDisabled: false,
    isRhinophoneEnabled: false,
    isDefaultGroupOutboundChannelEnabled: false,
  };

  componentDidMount() {
    this.props.fetchOrganizationProfileView(this.props.userOrganization.id);
    if (this.props.currentUser.isCcr) {
      this.props.fetchParentOrganizations();
      this.props.fetchMerchantCcr(this.props.userOrganization.id);
      this.props.fetchVideoOrgConfig(this.props.userOrganization.id);
      this.props.fetchCallOrgConfig(this.props.userOrganization.id);
      this.props.fetchSSOConnections();
      this.props.fetchFormConfiguration(this.props.userOrganization.id);
      this.props.fetchOrganizationRolesView();
      this.props.fetchFilterData('', 'members');
    }
  }

  componentDidUpdate(prevProps) {
    this.shapePropsForLocalState(prevProps, this.state); // eslint-disable-line react/no-did-update-set-state
  }

  get formInProgress() {
    let inProgress = this.props.isOrganizationFormInProgress;
    if (this.props.currentUser.isCcr) {
      if ((this.props.isRhinopayEnabled || this.state.isRhinopayEnabled)) {
        inProgress = inProgress || this.props.isOrgMerchantFormInProgress;
      }

      if (this.props.isVideoEnabled || this.state.isVideoEnabled) {
        inProgress = inProgress || this.props.isOrgVideoFormInProgress;
      }
    }
    return inProgress;
  }

  integrationSelectOptions = [
    { id: -1, value: '--' },
    { id: Types.TYPE_INTEGRATION_AXIUM, value: 'axiUm' },
    { id: Types.TYPE_INTEGRATION_CLOUD_9, value: 'Cloud9' },
    { id: Types.TYPE_INTEGRATION_DOLPHIN, value: 'Dolphin' },
    { id: Types.TYPE_INTEGRATION_EPIC, value: 'Epic' },
    { id: Types.TYPE_INTEGRATION_FOCUS_ORTHO, value: 'FocusOrtho' },
    { id: Types.TYPE_INTEGRATION_NEXTECH, value: 'Nextech' },
    { id: Types.TYPE_INTEGRATION_OPEN_DENTAL, value: 'Open Dental' },
    { id: Types.TYPE_INTEGRATION_MI7, value: 'MI7' },
    { id: Types.TYPE_INTEGRATION_SIKKA, value: 'Sikka' },
    { id: Types.TYPE_INTEGRATION_TOPS, value: 'Tops' },
    { id: Types.TYPE_INTEGRATION_CSV_IMPORT, value: 'CSV import' },
    { id: Types.TYPE_INTEGRATION_PIONEER_RX, value: 'PioneerRx' },
    { id: Types.TYPE_INTEGRATION_PROGNOCIS, value: 'PrognoCIS' },
  ];

  rhinopaySelectOptions = AppConstants.RHINOPAY_TYPE;

  handleChange = (name, value) => {
    const errors = { ...this.state.errors };
    const data = { [name]: value };
    const paymentErrors = AppConstants.PAYMENT_ERRORS;
    if (name === 'paymentType') {
      paymentErrors.forEach((error) => delete errors[error]);
      data.errors = errors;
    }
    this.setState(data);
  }

  handleAddParentOrganization = () => {
    this.setState({ isAddParentOrgModalOpen: true });
  }

  handleParentCompanyCloseModal = () => {
    this.setState({ isAddParentOrgModalOpen: false, companyName: '' });
  }

  saveParentCompany = () => {
    const { companyName, parentCompanyOptions } = this.state;
    const errors = {};

    if (!companyName) {
      errors.companyName = 'Company Name is required!';
    }
    const errorCount = Object.keys(errors).length;

    if (errorCount > 0) {
      this.setState({
        parentCompanyformErrors: errors,
      });
    } else {
      const payload = {
        name: companyName,
      };
      this.props.createParentOrganizations(payload).then((response) => {
        const { id, name } = response;
        parentCompanyOptions.push({ id, value: name });
        this.setState({
          parentCompanyId: id,
          isAddParentOrgModalOpen: false,
          companyName: '',
          parentCompanyOptions,
        });
      });
    }
  }

  handleUploadAvatar = (image, sourceFilename) =>
    UploadActions.uploadFileFromDataUrl(image, AVATAR_BUCKET, sourceFilename)
      .then((response) => {
        const newState = {
          organizationLogoUrl: response.file,
        };

        this.setState(newState);
      })
      .finally(() => {
        this.setState({
          uploadAvatarModalOpen: false,
        });
      });

  handleCloseModal = () => {
    this.setState({ uploadAvatarModalOpen: false });
  }

  handleAddPhoto = () => {
    this.setState({ uploadAvatarModalOpen: true });
  }

  shapeAddressForDB = (data) => ({
    street1: data.street1,
    street2: data.street2,
    city: data.city,
    state: data.state,
    zip: data.zip,
  });

  shapePayloadForRhinoapi = (data) => ({
    areHipaaRequestCampaignsEnabled: !!data.areHipaaRequestCampaignsEnabled,
    arePrescriptionCampaignsEnabled: !!data.arePrescriptionCampaignsEnabled,
    billManagerCustId: data.billManagerCustId,
    businessAddress: this.shapeAddressForDB(data),
    businessEmail: data.businessEmail,
    businessPhone: data.businessPhone,
    contactEmail: data.contactEmail,
    contactName: data.contactName,
    contactPhone: data.contactPhone,
    identityProvider: data.isSSOEnabled ? data.identityProvider : undefined,
    integration: !!data.integration,
    integrationPartnerTypeId: data.integrationPartnerTypeId === -1 ? '' : data.integrationPartnerTypeId,
    isActive: !!data.isActive,
    isAppointmentManagerEnabled: !!data.isAppointmentManagerEnabled,
    isAutomatedMessageToConnectedPartiesEnabled: !!data.isAutomatedMessageToConnectedPartiesEnabled,
    isBadgeCountPreferencesEnabled: !!data.isBadgeCountPreferencesEnabled,
    isBulkMessagingEnabled: !!data.isBulkMessagingEnabled,
    isCallEnabled: !!data.isCallEnabled,
    isCloseMentionsForAllMembersEnabled: !!data.isCloseMentionsForAllMembersEnabled,
    isDocumentWriteBackEnabled: [
      Types.TYPE_INTEGRATION_AXIUM,
      Types.TYPE_INTEGRATION_CLOUD_9,
      Types.TYPE_INTEGRATION_DOLPHIN,
      Types.TYPE_INTEGRATION_MI7,
      Types.TYPE_INTEGRATION_PROGNOCIS,
    ].includes(data.integrationPartnerTypeId)
      ? !!data.isDocumentWriteBackEnabled : false,
    isMessageTranslationFeatureEnabled: !!data.isMessageTranslationFeatureEnabled,
    isMFARequired: !!data.isMFARequired,
    isPrescriptionManagerEnabled: !!data.isPrescriptionManagerEnabled,
    isOfficeVCardEnabled: !!data.isOfficeVCardEnabled,
    isRoutingManagerEnabled: !!data.isRoutingManagerEnabled,
    isSavedContentEnabled: !!data.isSavedContentEnabled,
    isSoundPreferencesEnabled: !!data.isSoundPreferencesEnabled,
    isSSOEnabled: !!data.isSSOEnabled,
    isSSORolesEnabled: !!data.isSSORolesEnabled,
    isVideoEnabled: !!data.isVideoEnabled,
    isOneReminderPerCalendarDayEnabled: !!data.isOneReminderPerCalendarDayEnabled,
    isConfirmedCodifiedResponseAlertEnabled: !!data.isConfirmedCodifiedResponseAlertEnabled,
    accountPriority: data?.accountPriority,
    parentCompanyId: data?.parentCompanyId,
    name: data.name,
    organizationLogoUrl: data.organizationLogoUrl,
    isRhinoblastMaxEnabled: !!data.isRhinoblastMaxEnabled,
    isEditIntegratedContactEnabled: !!data.isEditIntegratedContactEnabled,
    areExtraRemindersEnabled: !!data.areExtraRemindersEnabled,
    enableCampaignAppointmentTypeAndOffice: !!data.enableCampaignAppointmentTypeAndOffice,
    disableMessagingHIPAAConsent: !!data.disableMessagingHIPAAConsent,
    isMessagingForMinorsDisabled: !!data.isMessagingForMinorsDisabled,
    isRhinophoneEnabled: !!data.isRhinophoneEnabled,
    isDefaultGroupOutboundChannelEnabled: !!data.isDefaultGroupOutboundChannelEnabled,
  });

  shapePayloadForRhinopay = (data) => ({
    organizationId: this.props.userOrganization.id,
    isEnabled: data.isRhinopayEnabled,
    paymentType: data.paymentType,
    merchantId: data.merchantId,
    apiPassword: data.paymentApiPassword,
    apiUserName: data.paymentApiUsername,
    merchantToken: data.merchantToken,
    gatewayId: data.paymentGatewayId,
    isACHEnabled: data.isAchEnabled,
  });

  shapePayloadForRhinoforms = (data) => ({
    organizationId: this.props.userOrganization.id,
    organizationName: this.props.userOrganization.name,
    isEnabled: data.isRhinoformEnabled,
    memberSeats: data.rhinoformSeatsLimit,
  });

  formatOrg = (organization) => {
    const paymentType = organization.paymentType === -1 ? -1 : this.rhinopaySelectOptions.find((p) => organization.paymentType === p.id).Key;
    return {
      ...organization,
      zip: organization.zip.trim(),
      state: organization.state === -1 ? -1 : this.props.states.find((s) => organization.state === s.id).code,
      paymentType,
      parentCompanyId: organization.parentCompanyId === -1 ? null : organization.parentCompanyId,
    };
  };
  shapePayloadForRhinovideo = (data) => ({
    orgId: this.props.userOrganization.id,
    isEnabled: data.isVideoEnabled,
    videoConferenceLimit: data.videoConferenceLimit,
  });

  shapePayloadForRhinocall = (data) => ({
    organizationId: this.props.userOrganization.id,
    isEnabled: data.isCallEnabled,
    channelLimit: data.channelLimit,
    isRhinophoneEnabled: data.isRhinophoneEnabled,
  });

  activeUsersIds = (selectedIds) => selectedIds?.filter((userId) => this.props.members[userId]?.active === true);

  handleSaveOrganization = () => {
    const rhinoFormUsers = this.props.organizationRoles.filter((permission) => permission.name === 'RhinoForms Admin')[0]?.users;
    const rhinoformActiveUsers = this.activeUsersIds(rhinoFormUsers)?.length;
    const formattedOrg = this.formatOrg(this.state);
    const errors = ValidationService(ValidationShapers.shapeOrganization({ ...formattedOrg }, this.props.currentUser.isCcr));
    const errorCount = Object.keys(errors).length;
    if (errorCount > 0) {
      this.setState({ errors }, () => {
        ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
      });
    } else if (this.state.isRhinoformEnabled && rhinoformActiveUsers > formattedOrg.rhinoformSeatsLimit) {
      this.setState({ errors: { seatLimitValidation: `Seat Limit should not be less than  ${rhinoformActiveUsers}` } }, () => {
        ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
      });
    } else if (this.state.isCallEnabled && this.props.rhinocallChannelIds?.length > formattedOrg.channelLimit) {
      this.setState({ errors: { channelLimit: `Channel Limit should not be less than  ${this.props.rhinocallChannelIds.length}` } }, () => {
        ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
      });
    } else if (!formattedOrg.billManagerCustId && this.props.billing && this.props.billing.billManagerCustId) {
      this.setState({ errors: { billManagerCustId: 'Billing Customer ID is required.' } }, () => {
        ValidationHelpers.handleValidationErrors(errors, this.pageContainer);
      });
    } else {
      this.setState({ errors });
      // These payloads are defined based on the service they are going to
      const payloadForRhinoapi = this.shapePayloadForRhinoapi(formattedOrg);
      const payloadForRhinopay = this.shapePayloadForRhinopay(formattedOrg);
      const payloadForRhinovideo = this.shapePayloadForRhinovideo(formattedOrg);
      const payloadForRhinocall = this.shapePayloadForRhinocall(formattedOrg);
      const payloadForRhinoforms = this.shapePayloadForRhinoforms(formattedOrg);
      // @TODO the condition on this IF statement is borderline illegible.
      if ((!this.props.billing && payloadForRhinoapi.billManagerCustId)
        || (payloadForRhinoapi.billManagerCustId && parseInt(payloadForRhinoapi.billManagerCustId, 10) !== parseInt(this.props.billing.billManagerCustId, 10))) {
        this.props.connectOrgWithCustId({ billManagerCustId: Number(payloadForRhinoapi.billManagerCustId) });
      }
      this.props.updateOrganizationFromProfile(payloadForRhinoapi)
        .finally(() => this.props.resetFormChanges());

      if (this.props.currentUser && this.props.currentUser.isCcr) {
        // rhinopay
        if (this.props.hasMerchantData && (this.props.isRhinopayEnabled || this.state.isRhinopayEnabled)) {
          this.props.updateMerchant(payloadForRhinopay);
        } else if (this.state.isRhinopayEnabled) {
          this.props.createMerchant(payloadForRhinopay);
        }
        if (this.props.videoConferenceLimit !== this.state.videoConferenceLimit || this.props.isVideoEnabled !== this.state.isVideoEnabled) {
          this.props.updateVideo(payloadForRhinovideo);
        }
        if (this.props.isCallEnabled !== this.state.isCallEnabled ||
          this.props.channelLimit !== this.state.channelLimit ||
          this.props.isRhinophoneEnabled !== this.state.isRhinophoneEnabled) {
          this.props.updateCall(payloadForRhinocall);
        }
        if (this.state.isRhinoformEnabled && !this.props.hasRhinoformOrgData) {
          this.props.createFormConfiguration(payloadForRhinoforms);
        } else if ((this.state.rhinoformSeatsLimit !== this.props.rhinoformSeatsLimit
          || this.state.isRhinoformEnabled !== this.props.isRhinoformEnabled) && this.props.hasRhinoformOrgData) {
          this.props.updateFormConfiguration(payloadForRhinoforms);
        }
      }
    }
  }

  shapePropsForLocalState(prevProps, prevState) {
    // Controls loader
    if (this.props.isOrganizationLoading !== prevState.isOrganizationLoading) {
      this.setState({ isOrganizationLoading: this.props.isOrganizationLoading });
    }
    // Sets form values
    if (this.props.isOrganizationFormInProgress !== prevState.isOrganizationFormInProgress) {
      if (!this.props.isOrganizationFormInProgress) {
        this.updateFormValuesFromProps();
      } else {
        this.setState({ isOrganizationFormInProgress: this.props.isOrganizationFormInProgress });
      }
    }
    // Sets Rhinopay form values
    if (this.props.isMerchantLoading !== prevState.isMerchantLoading) {
      this.setState({
        isMerchantLoading: this.props.isMerchantLoading,
        ...!this.props.isMerchantLoading && {
          isRhinopayEnabled: this.props.isRhinopayEnabled || false,
          merchantId: this.props.merchantId ? this.props.merchantId.toString() : '',
          merchantToken: this.props.merchantToken || '',
          paymentApiUsername: this.props.paymentApiUsername || '',
          paymentApiPassword: this.props.paymentApiPassword || '',
          paymentGatewayId: this.props.paymentGatewayId || '',
          isAchEnabled: this.props.isAchEnabled || false,
          paymentType: this.props.paymentType || -1,
        },
      });
    }
    // Sets Rhinoform form values
    if (this.props.isRhinoformOrgLoading !== prevState.isRhinoformOrgLoading) {
      this.setState({
        isRhinoformOrgLoading: this.props.isRhinoformOrgLoading,
        ...!this.props.isRhinoformOrgLoading && {
          isRhinoformEnabled: this.props.isRhinoformEnabled,
          rhinoformSeatsLimit: this.props.rhinoformSeatsLimit,
        },
      });
    }
    // Sets Rhinocall form values
    if (this.props.isRhinocallOrgLoading !== prevState.isRhinocallOrgLoading) {
      this.setState({
        isRhinocallOrgLoading: this.props.isRhinocallOrgLoading,
        ...!this.props.isRhinocallOrgLoading && {
          channelLimit: this.props.channelLimit || AppConstants.CALL_CHANNEL_LIMIT,
          isCallEnabled: this.props.isCallEnabled,
          isRhinophoneEnabled: this.props.isRhinophoneEnabled,
        },
      });
    }

    if (this.props.error && this.props.error !== prevProps.error) {
      const apiError = ValidationHelpers.handleServerError(this.props.error.data);
      this.setState({ errors: apiError });
    }
  }

  updateFormValuesFromProps() {
    const organization = this.props.userOrganization;
    const {
      billing,
      states,
      videoConferenceLimit,
      ssoConnections,
      parentCompanies,
    } = this.props;
    if (DataHelpers.hasData(organization)) {
      const {
        areHipaaRequestCampaignsEnabled,
        arePrescriptionCampaignsEnabled,
        businessEmail,
        businessPhone,
        contactEmail,
        contactName,
        contactPhone,
        identityProvider,
        integration,
        integrationPartnerTypeId,
        isActive,
        isAppointmentManagerEnabled,
        isAutomatedMessageToConnectedPartiesEnabled,
        isBadgeCountPreferencesEnabled,
        isBulkMessagingEnabled,
        isCloseMentionsForAllMembersEnabled,
        isDocumentWriteBackEnabled,
        isMessageTranslationFeatureEnabled,
        isMFARequired,
        isPrescriptionManagerEnabled,
        isOfficeVCardEnabled,
        isRoutingManagerEnabled,
        isSavedContentEnabled,
        isSoundPreferencesEnabled,
        isSSOEnabled,
        isSSORolesEnabled,
        isVideoEnabled,
        isOneReminderPerCalendarDayEnabled,
        isConfirmedCodifiedResponseAlertEnabled,
        accountPriority,
        parentCompany,
        name,
        organizationLogoUrl,
        isRhinoblastMaxEnabled,
        isEditIntegratedContactEnabled,
        areExtraRemindersEnabled,
        enableCampaignAppointmentTypeAndOffice,
        disableMessagingHIPAAConsent,
        isMessagingForMinorsDisabled,
        isDefaultGroupOutboundChannelEnabled,
      } = organization;

      const { street1, street2, city, state, zip } = organization.businessAddress ? organization.businessAddress : {};

      const stateCode = state && states?.find((s) => s.code === state.toUpperCase()) ? parseInt(states.find((s) => s.code === state.toUpperCase()).id, 10) : -1;
      const parentCompanyId = parentCompany?.id || -1;
      const parentCompanyOptions = [{ id: -1, value: '--' }];
      if (parentCompanies.length > 0) {
        parentCompanies.forEach((company) => {
          parentCompanyOptions.push({
            id: company.id,
            value: company.name,
          });
        });
      } else {
        parentCompanyOptions.push({
          id: parentCompany?.id,
          value: parentCompany?.name,
        });
      }

      const newStateObject = {
        areHipaaRequestCampaignsEnabled,
        arePrescriptionCampaignsEnabled,
        billManagerCustId: billing && billing.billManagerCustId && !this.props.loading ? billing.billManagerCustId.toString() : '',
        businessEmail: businessEmail || '',
        businessPhone: businessPhone || '',
        city: city || '',
        contactEmail: contactEmail || '',
        contactName: contactName || '',
        contactPhone: contactPhone || '',
        identityProvider,
        integration,
        integrationPartnerTypeId: integrationPartnerTypeId || -1,
        isActive,
        isAppointmentManagerEnabled,
        isAutomatedMessageToConnectedPartiesEnabled,
        isBadgeCountPreferencesEnabled,
        isBulkMessagingEnabled,
        isCloseMentionsForAllMembersEnabled,
        isDocumentWriteBackEnabled,
        isMessageTranslationFeatureEnabled,
        isMFARequired,
        isOrganizationFormInProgress: false,
        isPrescriptionManagerEnabled,
        isOfficeVCardEnabled,
        isRoutingManagerEnabled,
        isSavedContentEnabled,
        isSoundPreferencesEnabled,
        isSSOEnabled,
        isSSORolesEnabled,
        isVideoEnabled: isVideoEnabled || false,
        name: name || '',
        organizationLogoUrl: organizationLogoUrl || '',
        ssoConnections: ssoConnections || [],
        isOneReminderPerCalendarDayEnabled,
        isConfirmedCodifiedResponseAlertEnabled,
        accountPriority,
        parentCompanyId,
        parentCompanyOptions,
        state: stateCode,
        street1: street1 || '',
        street2: street2 || '',
        videoConferenceLimit: videoConferenceLimit || AppConstants.VIDEO_CONFERENCE_MEMBER_LIMIT,
        zip: zip || '',
        isRhinoblastMaxEnabled,
        isEditIntegratedContactEnabled,
        areExtraRemindersEnabled,
        enableCampaignAppointmentTypeAndOffice,
        disableMessagingHIPAAConsent,
        isMessagingForMinorsDisabled,
        isDefaultGroupOutboundChannelEnabled,
      };
      this.setState(newStateObject);
    }
  }

  render() {
    if (this.state.isOrganizationLoading || this.state.isMerchantLoading || this.state.isRhinoformOrgLoading || this.state.isRhinocallOrgLoading) {
      return <PageLoader />;
    }

    const props = {
      accountPriority: this.state.accountPriority,
      areExtraRemindersEnabled: this.state.areExtraRemindersEnabled,
      areHipaaRequestCampaignsEnabled: this.state.areHipaaRequestCampaignsEnabled,
      arePrescriptionCampaignsEnabled: this.state.arePrescriptionCampaignsEnabled,
      billingSetUp: this.props.billing,
      billManagerCustId: this.state.billManagerCustId,
      businessEmail: this.state.businessEmail,
      businessPhone: this.state.businessPhone,
      channelLimit: this.state.channelLimit,
      city: this.state.city,
      companyName: this.state.companyName,
      contactEmail: this.state.contactEmail,
      contactName: this.state.contactName,
      contactPhone: this.state.contactPhone,
      currentUser: this.props.currentUser,
      enableCampaignAppointmentTypeAndOffice: this.state.enableCampaignAppointmentTypeAndOffice,
      disableMessagingHIPAAConsent: this.state.disableMessagingHIPAAConsent,
      isMessagingForMinorsDisabled: this.state.isMessagingForMinorsDisabled,
      isDefaultGroupOutboundChannelEnabled: this.state.isDefaultGroupOutboundChannelEnabled,
      errorList: this.state.errors,
      formInProgress: this.formInProgress,
      handleAddParentOrganization: this.handleAddParentOrganization,
      handleAddPhoto: this.handleAddPhoto,
      handleChange: this.handleChange,
      handleCloseModal: this.handleCloseModal,
      handleFormChanges: this.props.handleFormChanges,
      handleParentCompanyCloseModal: this.handleParentCompanyCloseModal,
      handleSaveOrganization: this.handleSaveOrganization,
      hasRhinoformOrgData: this.props.hasRhinoformOrgData,
      identityProvider: this.state.identityProvider,
      integration: this.state.integration,
      integrationPartnerTypeId: this.state.integrationPartnerTypeId,
      integrationSelectOptions: this.integrationSelectOptions,
      isAchEnabled: this.state.isAchEnabled,
      isActive: this.state.isActive,
      isAddParentOrgModalOpen: this.state.isAddParentOrgModalOpen,
      isAppointmentManagerEnabled: this.state.isAppointmentManagerEnabled,
      isAutomatedMessageToConnectedPartiesEnabled: this.state.isAutomatedMessageToConnectedPartiesEnabled,
      isBadgeCountPreferencesEnabled: this.state.isBadgeCountPreferencesEnabled,
      isBulkMessagingEnabled: this.state.isBulkMessagingEnabled,
      isCallEnabled: this.state.isCallEnabled,
      isCloseMentionsForAllMembersEnabled: this.state.isCloseMentionsForAllMembersEnabled,
      isConfirmedCodifiedResponseAlertEnabled: this.state.isConfirmedCodifiedResponseAlertEnabled,
      isDocumentWriteBackEnabled: this.state.isDocumentWriteBackEnabled,
      isEditIntegratedContactEnabled: this.state.isEditIntegratedContactEnabled,
      isMessageTranslationFeatureEnabled: this.state.isMessageTranslationFeatureEnabled,
      isMFARequired: this.state.isMFARequired,
      isOfficeVCardEnabled: this.state.isOfficeVCardEnabled,
      isOneReminderPerCalendarDayEnabled: this.state.isOneReminderPerCalendarDayEnabled,
      isPrescriptionManagerEnabled: this.state.isPrescriptionManagerEnabled,
      isRhinoblastMaxEnabled: this.state.isRhinoblastMaxEnabled,
      isRhinoformEnabled: this.state.isRhinoformEnabled,
      isRhinoformOrgFormInProgress: this.props.isRhinoformOrgFormInProgress,
      isRhinopayEnabled: this.state.isRhinopayEnabled,
      isRhinophoneEnabled: this.state.isRhinophoneEnabled,
      isRoutingManagerEnabled: this.state.isRoutingManagerEnabled,
      isSavedContentEnabled: this.state.isSavedContentEnabled,
      isSoundPreferencesEnabled: this.state.isSoundPreferencesEnabled,
      isSSOEnabled: this.state.isSSOEnabled,
      isSSORolesEnabled: this.state.isSSORolesEnabled,
      isVideoEnabled: this.state.isVideoEnabled,
      loading: this.props.loading,
      merchantId: this.state.merchantId,
      merchantToken: this.props.merchantToken,
      name: this.state.name,
      organizationLogoUrl: this.state.organizationLogoUrl ? `${AppConstants.AVATAR_BASE_URL}${this.state.organizationLogoUrl}` : '',
      pageContainerRef: (pageContainer) => (this.pageContainer = pageContainer),
      parentCompanyformErrors: this.state.parentCompanyformErrors,
      parentCompanyFormInProgress: this.props.parentCompanyFormInProgress,
      parentCompanyId: this.state.parentCompanyId,
      parentCompanyOptions: this.state.parentCompanyOptions,
      paymentApiPassword: this.props.paymentApiPassword,
      paymentApiUsername: this.props.paymentApiUsername,
      paymentGatewayId: this.props.paymentGatewayId,
      paymentType: this.state.paymentType,
      rhinoformEmail: this.props.rhinoformEmail,
      rhinoformSeatsLimit: this.state.rhinoformSeatsLimit,
      rhinoformUsername: this.props.rhinoformUsername,
      rhinopaySelectOptions: this.rhinopaySelectOptions,
      saveParentCompany: this.saveParentCompany,
      selectedPaymentType: this.props.paymentType,
      ssoConnections: this.props.ssoConnections,
      state: this.state.state,
      stateOptions: [{ id: -1, value: '--', code: '--' }].concat(this.props.states),
      street1: this.state.street1,
      street2: this.state.street2,
      uploadAvatar: this.handleUploadAvatar,
      uploadAvatarModalOpen: this.state.uploadAvatarModalOpen,
      videoConferenceLimit: this.state.videoConferenceLimit,
      zip: this.state.zip,
    };

    return <OrganizationProfile {...props} />;
  }
}

OrganizationProfileContainer.propTypes = {
  billing: PropTypes.object,
  connectOrgWithCustId: PropTypes.func.isRequired,
  currentUser: PropTypes.object.isRequired,
  error: PropTypes.object,
  fetchOrganizationProfileView: PropTypes.func.isRequired,
  fetchMerchantCcr: PropTypes.func.isRequired,
  fetchVideoOrgConfig: PropTypes.func.isRequired,
  fetchCallOrgConfig: PropTypes.func.isRequired,
  fetchSSOConnections: PropTypes.func.isRequired,
  ssoConnections: PropTypes.array,
  organizations: PropTypes.object.isRequired,
  isOrganizationLoading: PropTypes.bool.isRequired,
  isOrganizationFormInProgress: PropTypes.bool.isRequired,
  updateOrganizationFromProfile: PropTypes.func.isRequired,
  userOrganization: PropTypes.object.isRequired,
  states: PropTypes.array.isRequired,
  handleFormChanges: PropTypes.func.isRequired,
  resetFormChanges: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isMerchantFormInProgress: PropTypes.bool,
  isRhinopayEnabled: PropTypes.bool,
  isVideoEnabled: PropTypes.bool,
  isCallEnabled: PropTypes.bool,
  isMerchantLoading: PropTypes.bool,
  paymentApiUsername: PropTypes.string,
  merchantToken: PropTypes.string,
  paymentGatewayId: PropTypes.string,
  paymentApiPassword: PropTypes.string,
  createMerchant: PropTypes.func,
  updateMerchant: PropTypes.func,
  isAchEnabled: PropTypes.bool,
  merchantId: PropTypes.string,
  hasMerchantData: PropTypes.bool,
  updateVideo: PropTypes.func,
  videoConferenceLimit: PropTypes.number,
  channelLimit: PropTypes.number,
  rhinoformUsername: PropTypes.string,
  rhinoformEmail: PropTypes.string,
  isRhinoformOrgFormInProgress: PropTypes.bool,
  rhinocallChannelIds: PropTypes.array,
  isOrgMerchantFormInProgress: PropTypes.bool,
  isOrgVideoFormInProgress: PropTypes.bool,
  isRhinocallOrgLoading: PropTypes.bool,
  isRhinoformOrgLoading: PropTypes.bool,
  updateFormConfiguration: PropTypes.func,
  updateCall: PropTypes.func,
  createFormConfiguration: PropTypes.func,
  rhinoformSeatsLimit: PropTypes.number,
  isRhinoformEnabled: PropTypes.bool,
  paymentType: PropTypes.string,
  hasRhinoformOrgData: PropTypes.bool,
  organizationRoles: PropTypes.array,
  members: PropTypes.object,
  fetchFormConfiguration: PropTypes.func,
  fetchOrganizationRolesView: PropTypes.func,
  fetchParentOrganizations: PropTypes.func,
  fetchFilterData: PropTypes.func,
  parentCompanies: PropTypes.array,
  createParentOrganizations: PropTypes.func,
  parentCompanyFormInProgress: PropTypes.bool,
  isRhinoblastMaxEnabled: PropTypes.bool,
  isEditIntegratedContactEnabled: PropTypes.bool,
  areExtraRemindersEnabled: PropTypes.bool,
  enableCampaignAppointmentTypeAndOffice: PropTypes.bool,
  disableMessagingHIPAAConsent: PropTypes.bool,
  isMessagingForMinorsDisabled: PropTypes.bool,
  isDefaultGroupOutboundChannelEnabled: PropTypes.bool,
  isRhinophoneEnabled: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const { organization, navigation, ui, billing, states, pay, rhinovideo, rhinocall, auth, form, role, auditLog } = state;
  return {
    billing: billing.billing,
    currentUser: getLoggedInUser(state),
    error: ui.error,
    isRhinopayEnabled: !!pay.merchant.isRhinopayEnabled,
    isVideoEnabled: !!rhinovideo?.org?.isVideoEnabled,
    isCallEnabled: !!rhinocall?.org?.isCallEnabled,
    isRhinophoneEnabled: !!rhinocall?.org?.isRhinophoneEnabled,
    loading: billing.loading,
    channelLimit: rhinocall?.org?.channelLimit || AppConstants.CALL_CHANNEL_LIMIT,
    hasMerchantData: DataHelpers.exists(pay.merchant.merchantId),
    hasRhinoformOrgData: !!form.org.username,
    isAchEnabled: pay.merchant.isAchEnabled,
    isMerchantFormInProgress: pay.isMerchantFormInProgress,
    isMerchantLoading: pay.isMerchantLoading,
    isOrganizationFormInProgress: organization.isOrganizationFormInProgress,
    isOrganizationLoading: organization.loading,
    isRhinocallOrgLoading: rhinocall.isOrgLoading,
    isRhinoformEnabled: !!form.org.isRhinoformEnabled,
    isRhinoformOrgFormInProgress: form.isOrgFormInProgress,
    isRhinoformOrgLoading: form.isOrgLoading,
    members: auditLog.members,
    merchantId: pay.merchant.merchantId,
    merchantToken: pay.merchant.merchantToken,
    navigation,
    organizationRoles: RoleHelpers.shapeRolePermissions(role.systemRoles),
    organizations: organization.organizations,
    parentCompanies: organization?.parentCompanies ?? [],
    parentCompanyFormInProgress: organization.parentCompanyFormInProgress,
    paymentApiPassword: pay.merchant.paymentApiPassword,
    paymentApiUsername: pay.merchant.paymentApiUsername,
    paymentGatewayId: pay.merchant.paymentGatewayId,
    paymentType: pay.merchant.paymentType,
    rhinocallChannelIds: getRhinocallChannelIds(state),
    rhinoformEmail: form.org.email || '',
    rhinoformSeatsLimit: form.org.seatsLimit || 1,
    rhinoformUsername: form.org.username || '',
    ssoConnections: auth.ssoConnections,
    states: states.states,
    userOrganization: getLoggedInUserOrganization(state),
    videoConferenceLimit: rhinovideo?.org?.videoConferenceLimit || AppConstants.VIDEO_CONFERENCE_MEMBER_LIMIT,
  };
};

const actions = {
  connectOrgWithCustId,
  createFormConfiguration: FormReducer.createOrgConfiguration,
  createMerchant,
  createParentOrganizations: OrganizationReducer.createParentOrganizations,
  fetchCallOrgConfig: RhinocallReducer.fetchRhinoCallOrganizationConfiguration,
  fetchFilterData: AuditLogReducer.fetchFilterData,
  fetchFormConfiguration: FormReducer.fetchOrgConfiguration,
  fetchMerchantCcr,
  fetchOrganizationProfileView: OrganizationReducer.fetchOrganizationProfileView,
  fetchOrganizationRolesView,
  fetchParentOrganizations: OrganizationReducer.fetchParentOrganizations,
  fetchSSOConnections: AuthReducer.fetchSSOConnections,
  fetchVideoOrgConfig: RhinovideoReducer.fetchRhinoVideoOrganizationConfiguration,
  receiveSSOConnections: AuthReducer.receiveSSOConnections,
  updateCall: RhinocallReducer.updateOrganizationConfiguration,
  updateFormConfiguration: FormReducer.updateOrgConfiguration,
  updateMerchant,
  updateOrganizationFromProfile: OrganizationReducer.updateOrganizationFromProfile,
  updateVideo: RhinovideoReducer.updateOrganizationConfiguration,
};

export default connect(mapStateToProps, actions)(unsavedChanges(OrganizationProfileContainer));
